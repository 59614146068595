<template>
  <div class="p-4">
    <a-divider orientation="left">Fundamentals</a-divider>
    <div class="col-lg-12">
      <p class="text-muted">
        On this page, we provide resources for learning basic ideas and concepts
        in Industrial and Manufacturing Engineering. It is not compulsory to
        know all these concepts, but they can further boost the value you
        extract from our solution. We are constantly adding more resources, and
        welcome requests for material from users, so please keep checking back.
      </p>
    </div>
    <a-row class="cards-wrapper" :gutter="[16, 32]">
      <a-col
        v-for="item in cards"
        :key="item.key"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
        class="item-center"
      >
        <div v-if="item.type === 'video'" class="item-box item-center">
          <!-- Video Card -->
          <div class="video-box">
            <fundamental-card-video :item="item" />
          </div>
          <div class="content-box p-4">
            <h5 class="item-title">{{ item.title }}</h5>
            <p class="item-description" v-html="item.message"></p>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import cardsConfig from './config';
import FundamentalCardVideo from './FundamentalCardVideo.vue';

export default {
  components: {
    FundamentalCardVideo,
  },
  data() {
    return {
      cards: cardsConfig.filter((item) => !item.isOutDated),
    };
  },
};
</script>
<style scoped>
.cards-wrapper {
  max-width: 1200px;
  padding: 2em 3em;
  margin: 0 auto !important;
}

.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-box {
  flex-direction: column;
  height: 520px;
  max-width: 385px;
  background: #fff;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.video-box,
.image-box {
  width: 100%;
  height: 250px;
  position: relative;
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-box {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.video-box iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-box {
  padding: 16px;
}

.item-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.item-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 16px;
}

/* Responsive styling for smaller screens */
@media (max-width: 576px) {
  .item-box {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .cards-box {
    padding: 0;
  }
}
</style>
