import { ASSETS } from 'src/constants/cdnConstants';

export default [
  {
    type: 'video',
    title: 'Importance of Standardized Work',
    message:
      'Standardized work is the backbone of efficiency in manufacturing. By setting clear, repeatable steps, we not only boost productivity, quality, and safety, but also provide a baseline for continuous improvement.',
    key: '1',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['14'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Practical Kaizen: 5S applied to Filter Assembly',
    message:
      "What if you could flip a switch in your teams' minds to help them spot and eliminate inefficiencies holding your business back? This fun and engaging video does just that—making it easy for you and your team to identify and reduce waste through simple, daily improvements.",
    key: '2',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['17'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'What is Kaizen?',
    message:
      'Kaizen in lean manufacturing is the practice of continuous, incremental improvement that empowers individuals at all levels to actively identify and implement process improvements, reducing waste, enhancing efficiency, and promoting a culture of shared responsibility for quality.',
    key: '3',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['1'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'What is Work?',
    message:
      'Work is a sequence of activities to produce a product demanded by the customer, and the Kaizen methodology highlights the contribution of each individual in making that product effectively.',
    key: '4',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['2'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Categorization of Work',
    message:
      'Work can be improved by categorizing it into three categories based on its value:<br/><i>VA (value-added):</i> Activities that fulfill customer demand.<br/> <i>NVA (non-value-added):</i> Activities that cause delays in production and can be eliminated through Kaizen.<br/> <i>NNVA (necessary non-value-added):</i> Activities that cause delays and cannot be eliminated but can be reduced.',
    key: '5',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['3'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Wastes of Lean: TIMWOODS',
    message:
      'There are 8 wastes of Lean, often remembered by the acronym TIMWOODS which stands for Time, Inventory, Motion, Waiting, Over processing, Over production, Defect, and, Skill. TIMWOODS is a process improvement mnemonic used to identify and eliminate waste in lean manufacturing operations.',
    key: '6',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['4'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Identification of Waste',
    message:
      'Operational waste can be identified using a flow process chart or process flow diagram by mapping each step in the workflow to reveal inefficiencies, such as excessive movement, waiting times, or redundant actions. By visually analyzing the flow of materials and information, it becomes easier to spot and address non-value-added activities that contribute to waste.',
    key: '7',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['5'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Kaizen Implementation',
    message:
      'For Kaizen implementation, we discuss two brainstorming approaches to reduce non-value-added work. One is a questioning process: 5W1H, and the other is a thinking process: ECRSSA. 5W1H stands for : What, Who, Where, When, How, and Why, whereas ECRSSA stands for : Eliminate, Combine, Re-sequence, Simplify, Split, and Automate.',
    key: '8',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['6'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Micromotion Analysis: Therbligs',
    message:
      'Therbligs is a micro-motion analysis tool used to standardize a workers movement at a workstation. It is divided into two categories: effective and ineffective. Effective therbligs contribute to value-added (VA) activities, while ineffective therbligs contribute to non-value-added (NVA) and necessary non-value-added (NNVA) activities.',
    key: '9',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['7'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Example of Therbligs',
    message:
      'Explanation of the necessity of micro-motion analysis (Therbligs) and how it is a crucial factor in improving worker ergonomics and efficiency.',
    key: '10',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['8'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Micromotion Analysis: MOST',
    message:
      'The MOST (Maynard Operation Sequence Technique) standard and Therbligs both analyze work elements in motion studies, but while Therbligs identify 18 basic motions in manual tasks, MOST uses broader sequence models to simplify complex tasks. MOST is more practical for faster analysis, whereas Therbligs provide detailed insights into finer movements.',
    key: '11',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['9'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Micromotion Analysis: PTMS',
    message:
      'Predetermined Time and Motion Studies analyze work tasks by setting standard times for specific actions and streamlining physical movements. These methods optimize efficiency, reduce waste, and improve consistency in operations, supporting lean manufacturing goals.',
    key: '12',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['10'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Designing an Assembly Line',
    message:
      'To design an assembly manufacturing line based on lean principles, the following essential elements are required for building any product: a drawing, bill of materials, assembly precedence diagram, planning, and line balancing. Takt time refers to the rate at which products must be produced to meet customer demand.',
    key: '13',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['11'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'What is BOM?',
    message:
      'The BOM (Bill of Materials) is a comprehensive checklist of all the parts and materials needed to manufacture a product. It can be considered a recipe for making that product.',
    key: '14',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['12'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Standardized Work',
    message:
      'Standardized work in lean manufacturing is a structured method that defines the most efficient and effective way to perform a particular task or process. It establishes a baseline of best practices, ensuring that tasks are consistently executed to achieve high quality, safety, and efficiency.',
    key: '15',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['13'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'Reach Zones for Worker Comfort',
    message:
      'Explanation of how to standardize the reach zone of a worker, depending on the symmetry of the workstation and the workflow.',
    key: '16',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['15'],
    isOutDated: false,
  },
  {
    type: 'video',
    title: 'What is a Work Cell?',
    message:
      'A work cell is generally set up in order to build a specific product or group of products. As opposed to arranging equipment and inventory in a departmental layout, a cellular approach eliminates wasted movement and minimizes assembly time. It also allows the same technician(s) to oversee the entire production sequence. The continuous flow of work cell manufacturing improves efficiency, quality assurance, and more.',
    key: '17',
    url: ASSETS.FUNDAMENTALS.VIDEO_URL['16'],
    isOutDated: false,
  },
];
